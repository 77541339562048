import { GetServerSidePropsContext, GetStaticPropsContext } from "next";
import {
    getAllPost,
    getDomainInfo,
    getAllUrls,
    getPostById,
    getDomainCategories,
} from "@components/severalbrands/utils/services";
const pages = [
    "HomePage",
    "Blog",
    "BlogId",
    "PartnerWithUs",
    "PrivacyPolicy",
    "CcpaPrivacyNotice",
    "HowWeWork",
    "keyConsiderations",
    "OurStory",
    "OurValues",
    "PartnerWithUs",
    "TermsOfUse",
    "404",
    "SignedRetainers",
] as const;
export const LIMIT = 3;
const initialCall = async (
    page: typeof pages[number],
    ctx?: GetStaticPropsContext | GetServerSidePropsContext,
    domainName?: string,
    postsLimit?: number,
) => {
    try {
        if (!pages.includes(page)) {
            return {
                notFound: true,
            };
        }

        switch (page) {
            case "HomePage":
            case "HowWeWork":
            case "OurStory":
            case "OurValues":
            case "keyConsiderations":
            case "PartnerWithUs":
            case "PrivacyPolicy":
            case "TermsOfUse":
            case "404":
            case "CcpaPrivacyNotice": {
                const response = await getDomainInfo(domainName);
                return {
                    props: {
                        domain: response?.data.data.domain,
                        disableTimeOnSite: true,
                    },
                };
            }

            case "Blog": {
                const domainRes = await getDomainInfo(domainName);

                const allPostsResponse = await getAllPost(
                    0,
                    postsLimit ?? LIMIT,
                    domainRes?.data.data.domain.id as number,
                    domainName,
                );

                return {
                    props: {
                        allPosts: allPostsResponse
                            ? allPostsResponse.data.data.allPosts
                            : [],
                        domain: domainRes?.data.data.domain,
                        disableTimeOnSite: true,
                    },
                };
            }
            case "BlogId": {
                const { params } = ctx as GetStaticPropsContext;
                const finalUrl = `/${(params?.blog as string[]).join("/")}`;
                const domainRes = await getDomainInfo(domainName);
                const allUrlsRes = await getAllUrls(domainName);
                const FoundPage = allUrlsRes?.data.data.allUrls.find(
                    (url) => url.url === finalUrl || url.main.url === finalUrl,
                );

                if (!FoundPage) {
                    return {
                        notFound: true,
                    };
                }
                const metaTitle = allUrlsRes?.data.data.allUrls.find(
                    (url) => url.modelId === FoundPage.modelId && url.isMain,
                )?.title;

                const postDataRes = await getPostById(
                    +FoundPage.modelId,
                    domainName,
                );
                const allPostsRes = await getAllPost(
                    0,
                    10,
                    domainRes?.data.data.domain.id as number,
                    domainName,
                );

                return {
                    props: {
                        allPosts: allPostsRes?.data.data.allPosts,
                        post: postDataRes?.data.data.post,
                        mainUrl: finalUrl,
                        domain: domainRes?.data.data.domain,
                        disableTimeOnSite: true,
                        metaTitle: metaTitle ?? null,
                    },
                    revalidate: parseInt(
                        (process.env.NEXT_PUBLIC_REVALIDATE_TIME as string) ??
                            60,
                    ),
                };
            }
            case "SignedRetainers": {
                const domainRes = await getDomainInfo(domainName);
                const categoriesRes = domainRes?.data?.data?.domain?.id
                    ? await getDomainCategories(
                          domainRes?.data?.data?.domain?.id,
                      )
                    : null;
                const categories = categoriesRes?.data.data.domain.categories;
                const category = categories?.find(
                    (category) => category.slug === ctx?.params?.category,
                );
                if (
                    !categories?.find(
                        (i) =>
                            ctx?.params?.category === i.slug ||
                            ctx?.params?.category === i.slugAlias,
                    )
                ) {
                    return {
                        notFound: true,
                    };
                }

                return {
                    props: {
                        domain: domainRes?.data.data.domain,
                        categories: categories ?? [],
                        category: category ?? null,
                        disableTimeOnSite: true,
                    },
                };
            }
        }
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log("sb error: ", error);
        return {
            props: {},
        };
    }
};

export default initialCall;
